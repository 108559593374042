import { useRef, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  notificationDataSelector,
  notificationOrderSelector,
  notificationLastKeySelector,
  notificationLoadingSelector,
} from "@/redux/app/selectors/notification";
import { Notification } from "./Notification";
import { ActionStatus } from "thunkless";
import { PaneMeasurementsCanvas } from "@/lib/navigation/pane-measurements-canvas";
import { UserInfoContext } from '../user/user-info';
import { filterNotifications } from "@/helpers/app/notification";
import { fetchAppNotifications } from "@/redux/app/actions/notification";
import { UILoader } from "../ui-elements/UILoader";
import { usePreviousValue } from "@/lib/react-hooks";
import { DynamicInfiniteList } from "../shared/DynamicInfiniteList";

export const Updates = () => {
  const dispatch = useDispatch();
  const {
    userId,
    broadcastFailedAppNotificationsEnabled,
    missedCallsAppNotificationsEnabled,
    agentNeededAppNotificationsEnabled,
    ticketAssignmentAppNotificationsEnabled,
  } = useContext(UserInfoContext);
  
  const order = useSelector(notificationOrderSelector);
  const notifications = useSelector(notificationDataSelector);
  const lastKey = useSelector(notificationLastKeySelector);
  const prevLastKey = usePreviousValue(lastKey);
  const loading = useSelector(notificationLoadingSelector)
  const [filtered, ids] = filterNotifications({
    notifications,
    order,
    enabled: {
      broadcast_failed: broadcastFailedAppNotificationsEnabled,
      agent_needed: agentNeededAppNotificationsEnabled,
      missed_call: missedCallsAppNotificationsEnabled,
      ticket_assignment: ticketAssignmentAppNotificationsEnabled,
    },
  })

  const renderItem = (index) => {
    if (!filtered[index]) return <div>wtf</div>
    return (
      <Notification
        key={`app-notification-${filtered[index].id}`}
        notification={filtered[index]}
      />
    )
  }

  const loadNextPage = useCallback(() => {
    if (!userId) return;
    if (lastKey < 0) return;
    if (lastKey === prevLastKey) return;

    dispatch(fetchAppNotifications({
      userId,
      lastKey: lastKey.toString(),
      limit: 2,
    }))

  }, [dispatch, userId, lastKey, prevLastKey])

  const containerRef = useRef(null);

  if (ids.length === 0) {
    return <Empty>No updates in the 2 past weeks</Empty>
  }

  const hasNextPage = lastKey !== prevLastKey && lastKey > 0;

  return (
    <>
      <div className="measurements-container" ref={containerRef} style={{ height: '300px' }}>
        <PaneMeasurementsCanvas className="measurements-canvas" $visible />
        <DynamicInfiniteList
          key="app-notifications-all-list"
          items={ids}
          renderItem={renderItem}
          hasNextPage={hasNextPage}
          isNextPageLoading={loading === ActionStatus.BUSY}
          loadNextPage={loadNextPage}
          loader={<UILoader size="sm" center />}
          loaderHeight={50}
        />
      </div>
    </>
  )
}

const Empty = styled.div`
  padding: 20px;
  font-size: 14px;
`;