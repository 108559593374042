import { useRef, useState } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { VariableSizeList as List } from 'react-window';
import { useCallback, useLayoutEffect } from 'react';
import { measureReactElements } from '@/lib/layout';

export const DynamicInfiniteList = ({
  items,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  loader,
  renderItem,
  loaderHeight,
  ...props
}) => {
  const [heights, setHeights] = useState([]);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const ref = useRef();
  const componentRef = useRef(null);

  useLayoutEffect(() => {
    if (!items.length) return;
    if (isMeasuring) return;

    setIsMeasuring(true);

    const elementsToMeasure = items.map(( id, idx ) => renderItem(idx));
    const listNode = componentRef.current._outerRef;

    const computeHeights = async () => {
      const computedHeights = await measureReactElements(listNode, elementsToMeasure);
      setHeights(computedHeights);
      setIsMeasuring(false);
    }

    computeHeights();
  }, [items, renderItem])

  useLayoutEffect(() => {
    (ref.current as any)._listRef?.resetAfterIndex?.(0); 
  }, [heights])

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = useCallback(
    (index) => !hasNextPage || index < items.length,
    [hasNextPage, items]
  )

  const itemSize = useCallback((index) => {
    if (!heights[index]) return 0;
    if (isItemLoaded(index)) return heights[index];
    return loaderHeight;
  }, [heights, loaderHeight]);

  // Render an item or a loading indicator.
  const Item = useCallback(({ index, style }) => {
    // if (isMeasuring) return null;
    const content = !isItemLoaded(index) ? loader : renderItem(index);
    return (
      <div id={items[index]} style={style}>
        {content}
      </div>
    );
  }, [isMeasuring, items, isItemLoaded, renderItem])

  return (
    <InfiniteLoader
      ref={ref}
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List
          ref={(refValue) => {
            // legacy react - ref is a callback that provides the ref as the value
            // save a reference to this list in prep for measureReactElements calculations
            ref(refValue);
            componentRef.current = refValue;
          }}
          onItemsRendered={onItemsRendered}
          itemCount={itemCount}
          height={300}
          width="100%"
          itemSize={itemSize}
          {...props}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
}