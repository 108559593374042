import { open, navigate } from "@/redux/app/actions/navigation";
import { Notification } from "@/redux/app/reducers/notification";
import { accountTicketPath, accountBroadcastPath } from "./routes";

export const evaluateVars = (notification: Notification) => {
  let renderHeader = '';
  let renderMessage = '';
  let path = '';
  let navigationAction: any = navigate;

  const {
    event_type,
    metadata: {
      header,
      message,
      url,
      ticket_uuid,
      account_subdomain,
      broadcast_hash_id,
      assigned_by_name,
      contact_name,
      broadcast_name
    },
  } = notification;

  switch (event_type) {
    case 'missed_call':
      renderHeader = 'Missed Call:';
      renderMessage = `${contact_name}.`
      path = accountTicketPath(account_subdomain, ticket_uuid)
      break;
    case 'ticket_assignment':
      renderHeader = 'Ticket Assignment:';
      renderMessage = `${assigned_by_name} assigned you to this ticket: ${contact_name}.`,
      path = accountTicketPath(account_subdomain, ticket_uuid)
      break;
    case 'product_release':
      renderHeader = `${header}:` || 'Avochato Release:';
      renderMessage = message;
      path = url;
      navigationAction = open;
      break;
    case 'broadcast_failed':
      renderHeader = 'Broadcast Failed:';
      renderMessage = `${broadcast_name}.`;
      path = accountBroadcastPath(account_subdomain, broadcast_hash_id)
      break;
    case 'agent_needed':
      renderHeader = 'Agent Needed:';
      renderMessage = 'AvoAI detected the need for an agent for this ticket.';
      path = accountTicketPath(account_subdomain, ticket_uuid)
      break;
  }

  return { header: renderHeader, message: renderMessage, path, navigationAction };
}

export const filterNotifications = ({
  notifications,
  order,
  enabled,
} : {
  notifications: Record<Notification['id'], Notification>;
  order: Notification['id'][];
  enabled: Record<Exclude<Notification['event_type'], 'product_release'>, boolean>;
}) : [Notification[], string[]] => {
  const filtered = [];
  const ids = [];

  order.forEach((id) => {
    const notif = notifications[id];
    if (enabled[notif.event_type] === true) {
      filtered.push(notif);
      ids.push(notif.id)
    }
  })

  return [filtered, ids];
}

export const productReleaseNotifs = ({
  notifications,
  order,
} : {
  notifications: Record<Notification['id'], Notification>;
  order: Notification['id'][];
}) : [Notification[], string[]] => {
  const arr = [];
  const ids = [];

  order.forEach((id) => {
    const notif = notifications[id];
    arr.push(notif);
    ids.push(notif.id)
  })

  return [arr, ids];
}